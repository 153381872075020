import { environment as env } from '@env/environment';

export interface Feature {
  name: string;
  description: string;
}

export const features: Feature[] = [
  {
    name: 'Who We Are',
    description:
      'For over 15 years we have consistently set the standard in an evolving industry.  Our record for pursuing and settling precedent setting cases have made us a leader in media asset protection.'
  },
  {
    name: 'Commitment to Our Clients',
    description:
      'The Law Office of D. Gill Sperlein is dedicated to ensuring you receive the best possible outcome.  We advise on a wide range of complex legal matters but we will not advocate for you in an area of law we do not specialize in.  If your dispute requires expertise in an area of law we do not serve, we will consult our extensive referral network for appropriate and reliable support'
  },
  {
    name: 'Service Areas',
    description:
      'We advise on a wide range of complex legal matters.  Do not hesitate to contact us with your concerns and questions.  We can assist you in everything from business formation to litigation.'
  }
];
