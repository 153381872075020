import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import { SettingsModule } from './settings';
import { StaticModule } from './static';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const DEFAULT_QUILL_CONFIG = {
  theme: 'snow',
  modules: {
    toolbar: true
  },
  placeholder: 'Empty canvas'
};
@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule, // toggled buttons

    // core & shared
    CoreModule,
    SharedModule,

    // features
    StaticModule,
    SettingsModule,

    // app
    AppRoutingModule
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
